<template>
  <section class="p-p-4">
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-4">
        <div class="p-inputgroup">
          <InputText placeholder="Keyword" v-model="name" @keydown.enter="addItemList" />
          <Button icon="pi pi-plus" class="p-button-raised p-button-help" @click="addItemList" />
        </div>
      </div>
    </div>
    <DataTable
      :value="listItems"
      responsive-layout="scroll"
      editMode="row"
      dataKey="id"
      v-model:editingRows="editingRows"
      @rowEditInit="onRowEditInit"
      @rowEditCancel="onRowEditCancel"
    >
      <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field">
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[slotProps.column.props.field]" />
        </template>
      </Column>
      <Column
        :rowEditor="true"
        style="width: 10%; min-width: 4rem"
        bodyStyle="text-align:right"
      ></Column>
      <Column style="width: 10%; min-width: 4rem" bodyStyle="text-align:left">
        <template #body="slotProps">
          <Button
            icon="pi pi-trash"
            class="p-button-text p-button-plain p-button-rounded"
            @click="removeItemList(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <div class="p-p-4">
      {{ listItems }}
    </div>
  </section>
</template>

<script>
import { ref } from '@vue/reactivity';

export default {
  setup() {
    const name = ref('');

    const listItems = ref([]);
    const columns = ref([{ field: 'value', header: 'Nombre' }]);
    const editingRows = ref([]);
    const originalRows = ref({});

    const addItemList = () => {
      const UID = Math.floor(Math.random() * 999999);
      listItems.value.push({ id: UID, value: name.value });
    };

    const removeItemList = (value) => {
      listItems.value = listItems.value.filter((item) => item.id !== value.id);
    };

    const onRowEditInit = (event) => {
      originalRows.value[event.index] = { ...listItems.value[event.index] };
    };

    const onRowEditCancel = (event) => {
      listItems.value[event.index] = originalRows.value[event.index];
    };

    return {
      listItems,
      columns,
      name,
      addItemList,
      removeItemList,
      editingRows,
      onRowEditInit,
      onRowEditCancel,
    };
  },
};
</script>

<style>
</style>
